import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export interface SEOProps {
  type: "website" | "article";
  subtitle?: string;
  description?: string;
  imageUrl?: string;
}

export const SEO = (props: SEOProps) => {
  const { pathname } = useLocation();

  const { site }: { site: Queries.Site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const title = `${site.siteMetadata?.title || ""}${
    props.subtitle ? ` - ${props.subtitle}` : ""
  }`;

  const description = props.description || site.siteMetadata?.description || "";

  const baseImgaeUrl = props.imageUrl || null;

  const imageUrl = baseImgaeUrl
    ? `${baseImgaeUrl}?auto=compress&auto=format`
    : null;

  return (
    <Helmet>
      <html lang="ja" />
      <head
        prefix={`og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# ${props.type}: http://ogp.me/ns/${props.type}#`}
      />

      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0"
      />
      <meta name="description" content={description} />
      {imageUrl && <meta name="image" content={imageUrl} />}

      <meta
        property="og:url"
        content={`${site.siteMetadata?.siteUrl || ""}${pathname}`}
      />
      <meta property="og:type" content={props.type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      <meta property="og:locale" content="ja_JP" />

      <meta name="twitter:card" content="summary_large_image" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}

      <script>
        {`
          (function(d) {
            var config = {
              kitId: '${process.env.ADOBE_FONTS_KIT_ID}',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
          })(document);
        `}
      </script>
    </Helmet>
  );
};
