import { Box, Button, Fade, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import facebook from "../assets/f_logo_RGB-Blue_1024.svg";
import twitter from "../assets/Twitter_Social_Icon_Rounded_Square_Color.svg";
import instagram from "../assets/glyph-logo_May2016.svg";
import other from "../assets/favicon.svg";

const SNSButton = (props: {
  src: string;
  alt: string;
  href: string;
  title: string;
}) => {
  const [linkVisible, setLinkVisible] = useState(false);

  return (
    <Grid item sm={3} xs={6} className="sns-icon">
      <Stack direction="column">
        <Button
          variant="text"
          className="icon"
          onClick={() => setLinkVisible((x) => !x)}
        >
          <img src={props.src} alt={props.alt} />
        </Button>
        <Fade in={linkVisible}>
          <Button variant="text" href={props.href}>
            {props.title}
          </Button>
        </Fade>
      </Stack>
    </Grid>
  );
};

export const SNS = () => {
  const [visibleClassName, setVisibleClassName] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setVisibleClassName("visible");
    }, 1500);
  }, []);

  return (
    <Box className={`sns ${visibleClassName}`}>
      <Grid container>
        <SNSButton
          src={facebook}
          alt="facebook"
          href="https://www.facebook.com/masafumi.fujimura/"
          title="masafumi fujimura"
        />
        <SNSButton
          src={twitter}
          alt="twitter"
          href="https://twitter.com/SirMiles90125"
          title="SirMiles90125"
        />
        <SNSButton
          src={instagram}
          alt="instagram"
          href="https://www.instagram.com/sirmiles90125/"
          title="sirmiles90125"
        />
        <SNSButton
          src={other}
          alt="other"
          href="mailto:info@miles-tone.com"
          title="mail"
        />
      </Grid>
    </Box>
  );
};
