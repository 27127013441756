import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

export const Subtitle = (props: {
  onHoverOrTouch: (category: string) => void;
}) => {
  const [visibleClassName, setVisibleClassName] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setVisibleClassName("visible");
    }, 2500);
  }, []);

  const itHandler = useCallback(() => {
    props.onHoverOrTouch("cat_it");
  }, []);
  const dataHandler = useCallback(() => {
    props.onHoverOrTouch("cat_data");
  }, []);
  const musicHandler = useCallback(() => {
    props.onHoverOrTouch("cat_music");
  }, []);

  return (
    <Box component="section" className={`subtitle ${visibleClassName}`}>
      <Box component="h2">
        <Box component="span" onTouchStart={itHandler} onClick={itHandler}>
          ITサービス・ソフトウェア開発
        </Box>
        、
        <Box component="span" onTouchStart={dataHandler} onClick={dataHandler}>
          データ分析
        </Box>
        、
        <Box
          component="span"
          onTouchStart={musicHandler}
          onClick={musicHandler}
        >
          音楽制作
        </Box>
      </Box>
    </Box>
  );
};
