import { graphql, HeadFC, PageProps } from "gatsby";
import * as React from "react";
import { useCallback } from "react";
import { Logo } from "../components/Logo";
import { MainBox } from "../components/MainBox";
import { Performances } from "../components/Performances";
import { SEO } from "../components/SEO";
import { SNS } from "../components/SNS";
import { Subtitle } from "../components/Subtitle";
import "../styles/styles.scss";

const IndexPage = ({ data }: PageProps<Queries.ListPerformancesQuery>) => {
  const clickedCategory = React.useRef("");

  const [selectedCategory, setSelectedCategory] = React.useState("");

  const clearHandler = useCallback(() => {
    if (clickedCategory.current === "") {
      setSelectedCategory("");
    }

    clickedCategory.current = "";
  }, []);

  const selectedData: Queries.ListPerformancesQuery = {
    allMicrocmsPerformances: {
      ...data.allMicrocmsPerformances,
      nodes: data.allMicrocmsPerformances.nodes.filter(
        (x) => x.category?.id === selectedCategory
      )
    }
  };

  return (
    <MainBox onClick={clearHandler}>
      <Logo />
      <Subtitle
        onHoverOrTouch={(category) => {
          clickedCategory.current = category;
          setSelectedCategory((x) => (x !== category ? category : ""));
        }}
      />
      <Performances data={selectedData} />
      <SNS />
    </MainBox>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>MilesTone</title>
    <SEO type="website" />
  </>
);

export const query = graphql`
  query ListPerformances {
    allMicrocmsPerformances(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        content
        title
        category {
          id
          name
        }
        publishedAt
      }
      totalCount
    }
  }
`;
