import { Box, Collapse, Fade } from "@mui/material";
import { PageProps } from "gatsby";
import React from "react";

export const Performances = (props: {
  data: Queries.ListPerformancesQuery;
}) => (
  <Fade
    in={props.data.allMicrocmsPerformances.nodes.length !== 0}
    timeout={{ enter: 1000, exit: 0 }}
  >
    <Box className="performances">
      <table className="">
        <thead>
          <tr>
            <th>At</th>
            <th>Title</th>
            <th>Contents</th>
          </tr>
        </thead>
        <tbody>
          {props.data.allMicrocmsPerformances.nodes.map((node) => (
            <React.Fragment key={node.id}>
              <tr>
                <td>
                  {node.publishedAt
                    ? `${new Date(node.publishedAt).getFullYear()}/${(
                        new Date(node.publishedAt).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}`
                    : "?"}
                </td>
                <th>{node.title}</th>
                <td dangerouslySetInnerHTML={{ __html: node.content || "" }} />
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Box>
  </Fade>
);
